import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

const Contact = (props)=>  {


    useEffect(() => {
        window.scrollTo(0, 0)

    }, []);




        return (
            <div>
                <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Term & Conditions</strong></div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{width : "99% " ,padding : "80px 30px"}}>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div style={{fontWeight :"bold"}}>
                                <h2>Terms and Conditions</h2>

<br/>
<h3>1. Mandatory Documentation:</h3>
<p>The following documents are mandatory to rent a vehicle from Motor Club Rent a Car karachi and must be produced at the counter:</p>
<ul>
    <li>Valid Passport & Emirates ID</li>
    <li>Valid Driving license issued at least 1 year before the pick-up date</li>
    <li>Valid International Driving license, where applicable, for customers on visit visas</li>
    <li>Credit Card in the name of the renter for security blocking</li>
</ul>
<p><strong>Important:</strong> For customers opting for delivery, the above documents should be uploaded at the time of booking creation or emailed to Motor Club at least two hours before the scheduled delivery to <a href="mailto:luqman@falcon-rides.com">luqman@falcon-rides.com</a>.</p>

<br/>
<h3>2. Delivery, Extension, Return, and Exchange:</h3>
<p>The minimum rental period is one day (24 hours) with a maximum grace period of 29 minutes. Late return may result in additional daily charges and fees. Customers should inform Motor Club in advance about any required extension. Rental Agreement remains valid until the Vehicle is returned to the agreed drop-off location. The Renter agrees to return the Vehicle with all documents and accessories in the same condition as received. Motor Club reserves the right to repossess the Vehicle if used in violation of the Agreement or if financial obligations are not settled.</p>

<br/>
<h3>3. Personal Data:</h3>
<p>The Renter entitles Motor Club to communicate the personal related data to concerned authorities such as the police, traffic departments, Criminal Investigation Departments, and other relevant bodies.</p>

<br/>
<h3>4. Rates & Modification:</h3>
<p>Rates are subject to change for modifications made after the reservation confirmation date. Where the Renter wishes to amend the initial period of rental – either by extension or reduction in rental days – the Renter acknowledges that Motor Club reserves the right to modify its rates accordingly.</p>

<br/>
<h3>5. Conditions of Use:</h3>
<p>The Renter is expected to use the Vehicle carefully. In particular, it must NOT be used:</p>
<ul>
    <li>To carry persons, the property of persons, or property for hire, except in the case of trucks and vans after getting Motor Club’s prior written approval</li>
    {/* <!-- Add more conditions as needed --> */}
</ul>
<p>The Renter agrees to be liable for any legal consequences or liabilities and to indemnify Motor Club against any and all losses incurred by Motor Club resulting from the Renter’s breach of any of the above conditions of use.</p>

<br/>
<h3>6. Insurance Policy:</h3>
<p>Motor Club provides standard insurance for persons using the Vehicle under an automobile liability policy. However, the Renter may opt for a higher level of insurance coverage after paying the applicable premium/rate as agreed with Motor Club and stated above in this Agreement.</p>

<br/>
<h3>7. Damage/Loss, Theft, etc.:</h3>
<p>In the event of damage, loss, or theft of the Vehicle or any parts of it, including fire and breakage of glass, the Renter shall, irrespective of his driver’s fault, pay to Motor Club the amount of all resulting loss and expenses.</p>
{/* <!-- Add more details as needed --> */}

<br/>
<h3>8. Governing Law and Jurisdiction:</h3>
<p>This Agreement is governed by and shall be construed and interpreted under the law of the United Arab Emirates as well as the local laws applicable to the Emirate where it is executed. If there is any dispute between the parties hereto, the same shall be submitted to the competent court.</p>

<br/>
<h3>9. Charges:</h3>
<p>Motor Club reserves the right to change its policies, including the applicable rates at any time without notice. Monthly rentals have a fixed mileage allowance per month. Excess mileage will be charged at Rs. 0.30 to Rs. 0.50 per additional km, depending on the car type.</p>
{/* <!-- Add more details as needed --> */}

<br/>
<h3>10. Payment, Cancellation & No-Show:</h3>
<p>A credit card in the name of the renter is mandatory for the car hire. Motor Club currently does not impose any cancellation or no-show fee on the bookings.</p>
{/* <!-- Add more details as needed --> */}

<br/>
<h3>11. Refund:</h3>
<p>Refunds for cancellation of bookings will be processed on the same credit card or cash that was used to pay online within 30 working days of the cancellation.</p>
{/* <!-- Add more details as needed --> */}

<br/>
<h3>12. Indemnity:</h3>
<p>Save for fraud and gross negligence, Motor Club shall not be held liable or responsible for any loss or damage incurred to or suffered by the Renter or any other third party in connection with the rented Vehicle. The Renter agrees to indemnify and hold Motor Club (and its subsidiaries, affiliated companies, employees and agents) harmless from and against all damages, losses, costs, expenses, actions, proceedings, claims and demands arising from or connected with the Renter’s breach of its obligations under this Agreement or with his/her driving of the Vehicle.</p>

<br/>
<h3>13. Language and Interpretation:</h3>
<p>These Terms and Conditions were drafted in English. They may be translated into other languages. In the event of a conflict between different languages, the English text shall prevail and be recognized for the sake of interpretation of both parties’ agreement.</p>

<br/>
<h3>14. Guaranteed Make & Model:</h3>
<p>Motor Club Guarantees make and model on specific car groups. In the event that these car groups are not available, Motor Club will provide an upgrade to the next available vehicle to the customer with the option to swap to the guaranteed vehicle type, as soon as it is available. Guaranteed vehicle type does not guarantee the vehicle color or optional accessories.</p>

<footer>
    <p>For inquiries or assistance, contact Motor Club at +971 56 361 9373 or <a href="mailto:luqman@falcon-rides.com">luqman@falcon-rides.com</a>.</p>
</footer>

                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    
}


export default Contact;