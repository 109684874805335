import Cars1 from "../assets/images/cars (8).png";
import Cars2 from "../assets/images/cars (2).png";
import Cars3 from "../assets/images/cars (3).png";
import Cars4 from "../assets/images/cars (4).png";
import Cars5 from "../assets/images/cars (5).png";
import Cars6 from "../assets/images/cars (6).png";
import Cars7 from "../assets/images/cars (7).png";

export const CAR_DATA = [
  [
    {
      name: "Bullet Proof V8 (Landcruiser)",
      price: "170000",
      img: Cars6,
      model: "Toyota",
      // mark: "Volkswagen",
      year: "2022",
      doors: "4/5",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Gasoline",
    },
  ],
  [
    {
      name: "Honda Civic 2023",
      price: "8000",
      img: Cars1,
      model: "Honda",
      // mark: "A1",
      year: "2023",
      doors: "4/5",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Gasoline",
    },
  ],
  [
    {
      name: "Toyota Prado TX",
      price: "17000",
      img: Cars5,
      model: "Toyota",
      // mark: "Toyota",
      year: "2023",
      doors: "4/5",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Gasoline",
    },
  ],
  [
    {
      name: "Fortuner Legender Limited",
      price: "15000",
      img: Cars4,
      model: "Toyota",
      // mark: "BMW",
      year: "2023",
      doors: "4/5",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Gasoline",
    },
  ],
  [
    {
      name: "Mercedes Benz C Class",
      price: "32000",
      img: Cars3,
      model: "Mercedes",
      // mark: "Mercedes",
      year: "2023",
      doors: "4/5",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Gasoline",
    },
  ],
  [
    {
      name: "Hiace Grand Cabin 224",
      price: "14000",
      img: Cars2,
      model: "Toyota",
      // mark: "Volkswagen",
      year: "2022",
      doors: "4/5",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Gasoline",
    },
  ],
];
